.custom-paragraph {
    font-family: Arial, sans-serif;
    font-size: 120%;
    margin-bottom: 5%;
}

.heading-in-modal {
    font-size: 170%;
    font-weight: 500;
    margin-bottom: 5%;
}

.gradient-button {
    /* background-image: linear-gradient(to bottom, #0074D9, #00ADEF); */
    background: linear-gradient(to right, #4e54c8, #8f94fb);
    color: white;
    padding: 10px 20px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}


@media all and (min-width: 400px) and (max-width: 600px) {
    .custom-paragraph {
        font-size: 80%;
    }
    .gradient-button {
        padding: 5px 10px;
        font-size: 13px;
    }
}

@media all and (min-width: 260px) and (max-width: 401px) {
    .custom-paragraph {
        font-size: 60%;
    }
    
    .heading-in-modal {
        font-size: 120%;
    }
    
    .gradient-button {
        padding: 3px 5px;
        font-size: 10px;
    }
}